import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  ContentContainer,
  ItemContainer,
  SubheaderText,
  RarityText,
  HeaderText,
  ItemParentContainer,
  StyledVideo,
} from "./MintInfoPage.styled";
import queryString from "query-string";
import emoji from "react-easy-emoji";
import { ClipLoader } from "react-spinners";
import { colors, RarityKind } from "../common/commonStyle";
import { StyledVideoWrapper } from "../components/GalleryVideo.styled";
import { IPFS_BASE_URL } from "../common/constants";
import { formatIpfsUrl } from "../common/utils";
import LazyLoad from "react-lazyload";

interface NFTAttribute {
  trait_type: string
  value: unknown
}

interface NFTData {
  name: string
  description: string
  external_url: string
  image: string
  attributes: NFTAttribute[]
}

interface NFTDisplayData {
  name: string
  image: string
  rarity: string
}

interface MintNftEntryProps {
  nft?: NFTDisplayData
}

function MintNftEntry(props: MintNftEntryProps) {
  const [isLoading, setLoading] = useState(true);

  const rarityKind = props.nft?.rarity.toLowerCase() as RarityKind;
  const content = props.nft ?
    <ItemContainer kind={rarityKind}>
      <SubheaderText>{props.nft.name}</SubheaderText>
      <RarityText kind={rarityKind}>{props.nft.rarity}</RarityText>
      <LazyLoad>
        <StyledVideo autoPlay muted loop onLoadedData={() => {
          setLoading(false);
        }} key={props.nft.image}>
          <source src={props.nft.image} type="video/mp4" />
            Your browser does not support the video tag.
        </StyledVideo>
      </LazyLoad>
      {isLoading &&
          <StyledVideoWrapper>
            <ClipLoader color={colors.text} size="4em" />
          </StyledVideoWrapper>}
    </ItemContainer>
    : <ItemContainer kind="uncommon">
      <SubheaderText>
        Loading...
      </SubheaderText>
      <RarityText kind="uncommon">
        ???
      </RarityText>
      <StyledVideo muted>
      </StyledVideo>
      <StyledVideoWrapper>
        <ClipLoader color={colors.text} size="4em" />
      </StyledVideoWrapper>
    </ItemContainer>;

  return content;
}

function MintInfoPage() {
  type NFTDisplayMap = { [key: string]: NFTDisplayData };

  const [nftIds, setNftIds] = useState<string[]>([]);
  const [nftData, setNftData] = useState<NFTDisplayMap>({});
  const routerLocation = useLocation();

  useEffect(() => {
    if (routerLocation?.search) {
      const searchData = queryString.parse(routerLocation.search, {
        arrayFormat: "comma"
      });

      if (searchData?.ids) {
        const ids: string[] = ([] as string[]).concat(searchData.ids);
        setNftIds(ids);

        const fetchPromises = ids.map(async (value) => {
          const url = `${IPFS_BASE_URL}/${value}`;
          const res = await fetch(url);
          const nftMetadata: NFTData = await res.json();

          const imageUrl = formatIpfsUrl(
            nftMetadata["image"].split("ipfs://")[1],
            true
          );

          const rarity = nftMetadata.attributes.filter((attr) => {
            return attr.trait_type === "Rarity";
          })[0].value as string;

          const tokenData = {
            name: nftMetadata.name,
            image: imageUrl,
            rarity: rarity,
          };

          setNftData((oldData) => {
            return {
              ...oldData,
              [value]: tokenData
            };
          });
        });

        Promise.all(fetchPromises);
      }
    }
  }, [routerLocation?.search]);

  const content = nftIds.map((id) => {
    return <MintNftEntry nft={nftData[id]} key={id} />;
  });

  return (
    <ContentContainer>
      <HeaderText>{emoji("🔨")} Forge successful! {emoji("🗡️")}</HeaderText>
      <ItemParentContainer>{content}</ItemParentContainer>
    </ContentContainer>
  );
}

{/* <StyledButton onClick={() => {
  const searchProps = queryString.parse(routerLocation.search, {
    arrayFormat: "comma"
  });
  const idStrings: string[] = searchProps.ids as string[];

  const newUrl = queryString.stringifyUrl({
    url: "/success",
    query: {
      ids: idStrings.map((id) => {
        return Number.parseInt(id) + idStrings.length;
      })
    }
  }, { arrayFormat: "comma" });
  console.log(newUrl);

  routerHistory.push(newUrl);
}}>Next</StyledButton> */}

export default MintInfoPage;

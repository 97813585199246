import styled from "@emotion/styled";
import * as styles from "../common/commonStyle";

export const ContentContainer = styled.div({
  textAlign: "center",
  backgroundColor: "#2D3047",
  color: styles.colors.main,
  display: "flex",
  flexFlow: "column",
  alignItems: "center",
  padding: "16px 0 32px 0",
  minHeight: "100vh"
});

export const HeaderText = styled.h1({
  color: styles.colors.text,
  marginTop: "0px",
  marginBottom: "16px",
  marginLeft: "8px",
  marginRight: "8px",
  fontSize: "30px",

  "@media(min-width: 600px)": {
    fontSize: "48px"
  },

  "@media (min-width: 768px)": {
    fontSize: "64px"
  },
});

export const ItemContainer = styled.div((props: styles.RarityKindProps) => {
  const colData = styles.rarityColors[props.kind || "uncommon"] || styles.rarityColors["uncommon"];

  return {
    backgroundColor: colData.background,
    padding: "0 16px 24px 16px",
    borderRadius: "20px",
    boxShadow: "5px 5px 0px #000",
    margin: "24px",
    position: "relative"
  };
});

export const ItemParentContainer = styled.div({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
});

export const SubheaderText = styled.h2({
  color: styles.colors.text,
  textShadow: "2px 2px rgba(0,0,0,0.5)",
  margin: "16px 0",
});

export const RarityText = styled.h3((props: styles.RarityKindProps) => {
  const colData = styles.rarityColors[props.kind || "uncommon"] || styles.rarityColors["uncommon"];

  return {
    color: colData.text,
    textShadow: "2px 2px rgba(0,0,0,0.5)",
    margin: "12px 0",
  };
});

export const StyledVideo = styled.video({
  width: "75vw",
  maxHeight: "85vh",

  "@media(min-width: 500px)": {
    width: "380px"
  },
});
import React, { useEffect, useState } from "react";
import { GiAnvilImpact, GiGoldBar, GiMeshNetwork, GiPaintBrush, GiPlainDagger } from "react-icons/gi";
import { ethers } from "ethers";

import MainInfoCard from "../components/MainInfoCard";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import GalleryVideo from "../components/GalleryVideo";
import * as AppConstants from "../common/constants";
import { ERC721, ERC721__factory, PolyBlade, PolyBlade__factory } from "../typechain";
import CountdownTimer from "../components/CountdownTimer";
import { ClimbingBoxLoader, PulseLoader } from "react-spinners";
import {
  AboutMainContainer,
  AboutMainEntryContainer,
  AboutEntryTitle,
  AboutTextIcon,
  ContentContainer,
  CreditFooter,
  GalleryNavLeftButton,
  GalleryNavRightButton,
  MainBannerButton,
  MainBannerButtonContainers,
  MainBannerContainer,
  MainBannerImage,
  MainBannerOverlayContainer,
  MainLoaderContainer,
  ModelIcon,
  ModelIconContainer,
  ModelIconTitle,
  PolygonIcon,
  ProgressBar,
  ProgressBarContainer,
  ProgressBarText,
  RarityTitle,
  TwitterTimelineContainer,
  TwitterTimelineContainerWrapper,
  AboutText,
  AboutContentContainer,
  AboutMainEntryImage,
  MintCounterButton,
  MintCounterContainer,
  MintCounterValueText,
  MintCounterIcon,
} from "./PolybladeHome.styled";
import emoji from "react-easy-emoji";
import { WHITELISTED_TOKEN_IDS } from "../common/constants";
import { formatIpfsUrl } from "../common/utils";

interface ContractState {
  price: ethers.BigNumber,
  saleStarts: number,
  minted: number,
  maxSupply: number,
  discounts: number
}

interface PolybladeProps {
  onError: (e: string, kind: "active" | "passive") => void,
  onGlobalLoad: (m: string | null) => void
}

// https://github.com/MetaMask/eth-rpc-errors/blob/main/src/error-constants.ts
const ERROR_MESSAGE_MAPPINGS: { [key: number]: string } = {
  4001: "Transaction cancelled",
  4100: "Unauthorized",
  4900: "Disconnected",
  4901: "Chain disconnected"
};

function PolybladeHome(props: PolybladeProps) {
  const [polyblade, setPolyblade] = useState<PolyBlade>();
  const [oldPolyblade, setOldPolyblade] = useState<ERC721>();
  const [contractState, setContractState] = useState<ContractState>({
    price: ethers.utils.parseEther("0"),
    saleStarts: -1,
    minted: 0,
    maxSupply: 0,
    discounts: 0
  });
  const [ownedOldTokens, setOwnedOldTokens] = useState<ethers.BigNumberish[]>([]);
  const [signer, setSigner] = useState<ethers.Signer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOldTokens, setIsLoadingOldTokens] = useState(true);
  const [mintCount, setMintCount] = useState(1);

  // Post-load contract
  useEffect(() => {
    try {
      updatePolybladeState(true);
    } catch (err) {
      console.error(err);
      props.onError("Something went wrong while trying to fetch contract data.", "passive");
    }
  }, [polyblade]);

  useEffect(() => {
    try {
      updateOldPolybladeState();
    } catch (err) {
      console.error(err);
      props.onError("Something went wrong while trying to fetch contract data.", "passive");
    }
  }, [oldPolyblade, signer]);

  async function updateOldPolybladeState() {
    setIsLoadingOldTokens(true);
    if (oldPolyblade && signer) {
      const getTokenData = async (token: ethers.BigNumberish) => {
        const owner = await oldPolyblade.ownerOf(token);
        return {token, owner};
      };

      const signerAddress = await signer.getAddress();
      const oldBalance = await oldPolyblade.balanceOf(signerAddress);

      if (oldBalance.gt(0)) {
        const oldTokenPromises = [];
        for (const token of WHITELISTED_TOKEN_IDS) {
          oldTokenPromises.push(getTokenData(token));
        }

        const oldTokenPromiseResult = await Promise.all(oldTokenPromises);
        const ownedOldTokens = oldTokenPromiseResult.filter((value) => {
          return value.owner === signerAddress;
        }).map((value) => {
          return value.token;
        });

        setOwnedOldTokens(ownedOldTokens);
      }
    }
    setIsLoadingOldTokens(false);
  }

  async function updatePolybladeState(withLoading: boolean) {
    if (polyblade) {
      if (withLoading) {
        setIsLoading(true);
      }

      const statePromises = {
        price: polyblade.price(),
        mintsRemaining: polyblade.mintsRemaining(),
        saleStarts: polyblade.saleStart(),
        maxSupply: polyblade.maxSupply(),
        discounts: polyblade.discountedMintsRemaining()
      };

      const newState = {
        price: (await statePromises.price),
        minted: (await statePromises.maxSupply)
                - (await statePromises.mintsRemaining),
        saleStarts: (await statePromises.saleStarts).toNumber(),
        maxSupply: (await statePromises.maxSupply),
        discounts: (await statePromises.discounts)
      };

      if (newState.discounts > 0) {
        setMintCount(1);
      }

      setContractState(newState);

      if (withLoading) {
        setIsLoading(false);
      }
    }
  }

  // Load contract
  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider(AppConstants.RPC_URL);

    const contract = PolyBlade__factory.connect(AppConstants.CONTRACT_ADDRESS, provider);
    setPolyblade(contract);

    const oldContract = ERC721__factory.connect(AppConstants.OLD_CONTRACT_ADDRESS, provider);
    setOldPolyblade(oldContract);
    promptWallet();
  }, []);

  function promptWallet() {
    const ethereumProvider = (window as any).ethereum;

    if (ethereumProvider) {
      // Reload page on changing chain
      ethereumProvider.on("chainChanged", () => window.location.reload());

      ethereumProvider.enable().then(async () => {
        const provider = new ethers.providers.Web3Provider(ethereumProvider);
        const signer = provider.getSigner();

        const chainId = await ethereumProvider.request({ method: "eth_chainId" });
        
        if (chainId === AppConstants.CHAIN_ID) {
          setSigner(signer);
        } else {
          props.onError("Wrong network. Please switch to the MATIC network.", "passive");
          await promptNetworkSwitch(ethereumProvider);
        }
      }).catch((err: any) => {
        console.error(err);
        props.onError("Something went wrong when connecting to the Ethereum provider.", "passive");
      });
    } else {
      props.onError("Ethereum wallet provider not found. See: https://metamask.io/", "passive");
    }
  }

  async function promptNetworkSwitch(ethereumProvider: any) {
    try {
      await ethereumProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: AppConstants.CHAIN_ID }],
      });
    } catch (e) {
      // Chain not added to MM
      // https://stackoverflow.com/questions/68252365/how-to-trigger-change-blockchain-network-request-on-metamask
      if ((e as any).code === 4902) {
        try {
          await ethereumProvider.request({
            method: "wallet_addEtehereumChain",
            params: [
              {
                chainId: AppConstants.CHAIN_ID,
                rpcUrl: AppConstants.RPC_URL
              }
            ]
          });
        } catch (e2) {
          console.error(e2);
          props.onError(
            "Failed to add MATIC network to wallet provider. Please add it manually: https://chainlist.org/",
            "passive"
          );
        }
      } else {
        console.error(e);
        props.onError(
          "Failed to switch chains. Ensure the MATIC network is added to your wallet provider and that you are connected, and refresh.",
          "passive"
        )
      }
    }
  }

  // function extractTransferredTokens(toAddress: string, receipt: ethers.ContractReceipt): number[] {
  //   if (receipt.events !== undefined && receipt.events.length !== 0) {
  //     const tokenIds = receipt.events.filter((ev) => {
  //       // Transfer to signer
  //       return ev.event === "Transfer" && ev.args && ev.args[1] === toAddress;
  //     }).map((ev) => {
  //       // Non-null assertion done in previous block
  //       return ev.args![2];
  //     });

  //     return tokenIds as number[];
  //   } else {
  //     return [];
  //   }
  // }

  async function extractRandomMintTokens(receipt: ethers.ContractReceipt): Promise<number[] | null> {
    const tokenIdPromises = receipt.events?.filter((ev) => {
      return ev.event === "RequestMint";
    }).map((ev) => {
      return ev.args![0] as string;
    }).map(async (requestId) => {      
      return await new Promise<number>((resolve) => {
        polyblade!.on(polyblade!.filters.FulfillMint(requestId), (_, tokenId) => {
          resolve(tokenId.toNumber());
        });
      });
    });

    if (tokenIdPromises) {
      return Promise.all(tokenIdPromises); 
    } else {
      return null;
    }
  }

  async function onMint() {
    if (!signer) {
      props.onError("Please sign in with your wallet first!", "active");
      return;
    }

    if (polyblade) {
      props.onGlobalLoad("Minting in progress");

      try {
        const polybladeWithSigner = polyblade.connect(signer);

        let tx = null;
        if (mintCount == 1) {
          tx = await polybladeWithSigner.mint({
            value: await polyblade.price()
          });
        } else { 
          tx = await polybladeWithSigner.batchMint(mintCount, {
            value: (await polyblade.price()).mul(mintCount)
          });
        }
        
        const receipt = await tx.wait();
        const mintedIds = await extractRandomMintTokens(receipt);

        if (mintedIds !== null) {
          openSuccessScreen(mintedIds);
          updatePolybladeState(false);
        } else {
          props.onError(
            "Could not extract minted token data. " +
            "Check your wallet to see if you have received a PolyBlade token.",
            "active"
          );
        }
      } catch (e) {
        console.error(e);
        handleError(e);
      } finally {
        props.onGlobalLoad(null);
      }
    }
  }

  async function onClaim() {
    if (!signer) {
      props.onError("Please sign in with your wallet first!", "active");
      return;
    }

    if (ownedOldTokens.length === 0) {
      props.onError("You do not have any tokens to claim!", "active");
      return;
    }

    if (polyblade && oldPolyblade) {
      props.onGlobalLoad("Claiming in progress");

      try {
        const oldPolybladeWithSigner = oldPolyblade.connect(signer);
        const approvedAddress = await oldPolybladeWithSigner.getApproved(ownedOldTokens[0]);

        // Get approval
        if (approvedAddress !== polyblade.address) {
          const approveTx = await oldPolybladeWithSigner.approve(polyblade.address, ownedOldTokens[0]);
          await approveTx.wait();
        }

        // Claim token
        const claimTx = await polyblade.connect(signer).claim(ownedOldTokens[0]);
        const receipt = await claimTx.wait();
        const mintedIds = await extractRandomMintTokens(receipt);

        if (mintedIds !== null) {
          openSuccessScreen(mintedIds);
          updatePolybladeState(false);
        } else {
          props.onError(
            "Could not extract minted token data. " +
            "Check your wallet to see if you have received a PolyBlade token.",
            "active"
          );
        }
      } catch (e) {
        console.error(e);
        handleError(e);
      } finally {
        props.onGlobalLoad(null);
      }
    }
  }

  function handleError(e: any) {
    let errorMsg = "Minting failed! Please refresh and try again";
    if (ERROR_MESSAGE_MAPPINGS[e.code as number]) {
      errorMsg = ERROR_MESSAGE_MAPPINGS[e.code as number];
    } else if (e.data.message) {
      errorMsg = e.data.message;

      // Prettify error
      if (errorMsg.includes("insufficient funds")) {
        errorMsg = "Insufficient funds!";
      } else if (errorMsg.includes("execution reverted:")) {
        errorMsg = errorMsg.split("execution reverted:")[1].trim();
      } else if (errorMsg.includes("err:")) {
        errorMsg = errorMsg.split("err:")[1].trim();
      }
    } else if (e.data.error) {
      errorMsg = e.data.error;
    }

    props.onError(errorMsg, "active");
  }

  function openSuccessScreen(mintedIds: number[]) {
    console.log(mintedIds);
    const idsQueryParam = mintedIds.map((id) => { return id.toString(); }).join(",");
    const successTab = window.open(`/#/success?ids=${idsQueryParam}`, "_blank");
    if (successTab !== null) {
      successTab.focus();
    }
  }

  function onCounter(up: boolean) {
    setMintCount((oldValue) => {
      const increment = up ? 1 : -1;
      const newValue = Math.max(1, Math.min(AppConstants.MAX_MINT_COUNT, oldValue + increment));
      return newValue;
    });
  }

  const bladeIcons = [
    "./model-icons/kara.png", "./model-icons/tanto.png", "./model-icons/cutlass.png",
    "./model-icons/switch.png", "./model-icons/bayonet.png", "./model-icons/gladius.png",
    "./model-icons/butterfly.png", "./model-icons/dagger.png", "./model-icons/laser.png",
    "./model-icons/laser2.png"
  ].map((icon, id) =>
  {
    return <ModelIcon src={icon} key={id} kind="uncommon" />;
  });

  const genesisIcons = [
    "./model-icons/kara-genesis.png", "./model-icons/tanto-genesis.png", "./model-icons/cutlass-genesis.png",
    "./model-icons/switch-genesis.png", "./model-icons/bayonet-genesis.png", "./model-icons/gladius-genesis.png",
    "./model-icons/butterfly-genesis.png", "./model-icons/dagger-genesis.png", "./model-icons/laser-genesis.png",
    "./model-icons/sword-genesis.png"
  ].map((icon, id) =>
  {
    return <ModelIcon src={icon} key={id} kind="mythic" />;
  });

  const legendaryIcons = [
    "./model-icons/sword-rare1.png", "./model-icons/sword-rare2.png", "./model-icons/sword-rare3.png",
    "./model-icons/sword-rare4.png", "./model-icons/sword-rare5.png", "./model-icons/sword-rare6.png",
    "./model-icons/sword-rare7.png", "./model-icons/sword-rare8.png", "./model-icons/sword-rare9.png",
    "./model-icons/sword-rare10.png"
  ].map((icon, id) => {
    return <ModelIcon src={icon} key={id} kind="legendary" />;
  });

  const swordIcons = [
    "./model-icons/sword-1.png", "./model-icons/sword-2.png", "./model-icons/sword-3.png",
    "./model-icons/sword-4.png", "./model-icons/sword-5.png", "./model-icons/sword-6.png",
    "./model-icons/sword-7.png", "./model-icons/sword-8.png", "./model-icons/sword-9.png",
    "./model-icons/sword-10.png"
  ].map((icon, id) => {
    return <ModelIcon src={icon} key={id} kind="epic" />;
  });

  const images: Array<ReactImageGalleryItem> = [
    "QmZarK1EQB4C7wwmpGUhzUvuyrxRRZ5CK7eBhFNZ14BLgp",
    "QmSShnXgK3XJWvVVCjo7dyohuFFeTgEWfXbAz4USFjLw1Q",
    "QmP2neh8pudemCnjf2FZyzxnUh6hS8jRzLorU6V4uD4aZi",
    "QmdAtmd3zt3vqxFAYaTnURJsY2tWhjLDgdF1EDuCUmDym9",
    "Qma4omggAe4SK5Y4dJvLG5oWBuoWABaVmfXM293BcYw69v",
    "QmQcJ9FaoC5aes8rHbArXcvqU8GaTtU37bwB3iKVmbubeH",
    "QmQc7xUZHQDkbDuAMrDF8w5gbupKDgEHvh7xJv9vK888zv",
    "QmWCFLCRxMCoyqHEdh7hgpqDqSjhq46mD3jw2RsDYbb5Zy",
    "QmViqcDXUxxz8o34dJKBfG3CHJDrAcJUrXTzjWjBfxBHzk",
    "QmWQn8cUdsCU8o6m4PiQTEjLY41YnrvKP4YhQAZyCV6HPx",
    "QmaHkLJxdbJSPRo6fqSimZ7n6pCP2XkZCLRJk53xcXq5bu"
  ].map((value) => {
    return {
      original: formatIpfsUrl(value, true)
    };
  });

  const isCountdown = contractState.saleStarts > Math.floor(Date.now() / 1000);

  const progressElement = isLoading ? <MainLoaderContainer>
    <ClimbingBoxLoader color="#19AEEE" />
  </MainLoaderContainer> :
    isCountdown ? <MainBannerButtonContainers>
      <CountdownTimer targetTime={contractState.saleStarts} />
    </MainBannerButtonContainers> :
      <ProgressBarContainer>
        <ProgressBarText>{contractState.minted} / {contractState.maxSupply} forged</ProgressBarText>
        <ProgressBar value={contractState.minted} max={contractState.maxSupply}></ProgressBar>
      </ProgressBarContainer>;

  const mintCounterElements = [1,2,3,4,5].map((val) => {
    return <MintCounterIcon key={val} active={mintCount >= val}>{emoji("🗡️")}</MintCounterIcon>;
  });

  const mainBannerContent = <>
    {progressElement}
    <MainBannerButtonContainers>
      {!isLoading && !isCountdown && <div><MintCounterContainer>
        <MintCounterButton onClick={() => { onCounter(false); }} disabled={mintCount <= 1}>
          {emoji("🔻")}
        </MintCounterButton>
        {mintCounterElements}
        <MintCounterButton onClick={() => { onCounter(true); }} disabled={
          mintCount >= (contractState.discounts > 0 ? 1 : AppConstants.MAX_MINT_COUNT)
        }>
          {emoji("🔺")}
        </MintCounterButton>
      </MintCounterContainer>
      <MintCounterValueText style={{ color: "white" }}>
        {ethers.utils.formatEther(contractState.price.mul(mintCount))} <PolygonIcon
          src="./matic-logo.svg"
          size="md"
        />
      </MintCounterValueText>
      </div>}
      <MainBannerButton onClick={onMint} disabled={isCountdown || isLoading}>
        Forge<br/>
      </MainBannerButton>
      {!isLoading && !isCountdown && <a href={AppConstants.OPENSEA_LINK}><MainBannerButton>
        Opensea
      </MainBannerButton></a>}
      {(isLoadingOldTokens || ownedOldTokens.length !== 0) && <MainBannerButton onClick={onClaim} disabled={
        isCountdown || isLoading || isLoadingOldTokens || ownedOldTokens.length === 0
      }>
        {(isLoadingOldTokens) ? <PulseLoader color="#F7F7F9" /> : <>Claim</>}
      </MainBannerButton>}
    </MainBannerButtonContainers>
  </>;

  return (
    <ContentContainer>
      <MainBannerContainer>
        <MainBannerOverlayContainer>
          <div>
            <MainBannerImage src="./banner.png" />
          </div>
          {mainBannerContent}
        </MainBannerOverlayContainer>
      </MainBannerContainer>

      <div>
        <MainInfoCard title={"What is $POLYBLADE?"} icon={emoji("🗡️")}>
          <AboutMainContainer>
            <AboutMainEntryContainer>
              <AboutMainEntryImage src="./blades-icon.png" />
              <AboutContentContainer>
                <AboutEntryTitle>Digital polygon blades</AboutEntryTitle>
                <AboutText>
                  <AboutTextIcon>
                    <PolygonIcon src="./matic-logo.svg" size="sm" style={{
                      margin: "0px 0.05em 0px 0.1em",
                      verticalAlign: "-0.1em",
                    }} />
                  </AboutTextIcon>
                  <span>
                    <b>3D NFT</b> collectibles powered by{" "}
                    <a href="https://polygon.technology/"><b>$POLYGON</b></a>
                  </span>
                </AboutText>
              </AboutContentContainer>
            </AboutMainEntryContainer>

            <AboutMainEntryContainer>
              <GiAnvilImpact/>
              <AboutContentContainer>
                <AboutEntryTitle>Smithed with 3D software</AboutEntryTitle>
                <AboutText>
                  <AboutTextIcon>
                    {emoji("🧊")}
                  </AboutTextIcon>
                  <span>
                    Each NFT contains a <b>3D model</b>, <b>PNG icon</b> &#38; <b>MP4 video</b>
                  </span>
                </AboutText>
              </AboutContentContainer>
            </AboutMainEntryContainer>

            <AboutMainEntryContainer>
              <GiPaintBrush/>
              <AboutContentContainer>
                <AboutEntryTitle>Coated with computer-generated paint</AboutEntryTitle>
                <AboutText>
                  <AboutTextIcon>
                    {emoji("🤖")}
                  </AboutTextIcon>
                  <span>
                    Each texture is uniquely <b>generated</b>
                  </span>
                </AboutText>
              </AboutContentContainer>
            </AboutMainEntryContainer>

            <AboutMainEntryContainer>
              <GiGoldBar/>
              <AboutContentContainer>
                <AboutEntryTitle>Forged as a non-fungible token</AboutEntryTitle>
                <AboutText>
                  <AboutTextIcon>
                    <PolygonIcon src="./chainlink-logo.svg" size="sm" style={{
                      margin: "0px 0.05em 0px 0.1em",
                      verticalAlign: "-0.1em",
                    }} />
                  </AboutTextIcon>
                  <span>
                    Fairly distributed using <b>Chainlink</b>{" "}
                    <a href="https://docs.chain.link/docs/chainlink-vrf/"><b>VRF</b></a>
                  </span>
                </AboutText>
                <AboutText>
                  <AboutTextIcon>
                    {emoji("💎")}
                  </AboutTextIcon>
                  <span>
                    Supply capped to <b>10 000</b> tokens
                  </span>
                </AboutText>
                <AboutText>
                  <AboutTextIcon>
                    {emoji("🪙")}
                  </AboutTextIcon>
                  <span>
                    <b>Fixed price</b> (except rare discount/giveaway events)
                  </span>
                </AboutText>
              </AboutContentContainer>
            </AboutMainEntryContainer>

            <AboutMainEntryContainer>
              <GiMeshNetwork/>
              <AboutContentContainer>
                <AboutEntryTitle>Served via the decentralized web</AboutEntryTitle>
                <AboutText>
                  <AboutTextIcon>
                    {emoji("🪐")}
                  </AboutTextIcon>
                  <span>
                    Hosted on <b>IPFS</b>
                  </span>
                </AboutText>

                <AboutText>
                  <AboutTextIcon>
                    {emoji("🌿")}
                  </AboutTextIcon>
                  <span>
                    Ecofriendly NFTs powered by <b>Proof-of-Stake</b> blockchain
                  </span>
                </AboutText>
                <AboutText>
                  <AboutTextIcon>
                    {emoji("📝")}  
                  </AboutTextIcon>
                  <span>
                    ERC721 Contract:{" "}
                    <a href={`https://polygonscan.com/token/${AppConstants.CONTRACT_ADDRESS}`}>
                      <b>{AppConstants.CONTRACT_ADDRESS}</b>
                    </a>
                  </span>
                </AboutText>
              </AboutContentContainer>
            </AboutMainEntryContainer>
          </AboutMainContainer>
        </MainInfoCard>

        <MainInfoCard title={"Blade Distribution"} icon={emoji("⚔️")}>
          <div>
            <ModelIconContainer>
              {bladeIcons}
            </ModelIconContainer>
            <ModelIconTitle>11 blades (9x1000, 2x500)</ModelIconTitle>
          </div>

          <div>
            <ModelIconContainer>
              {genesisIcons}
            </ModelIconContainer>
            <ModelIconTitle>10 <RarityTitle kind="mythic">mythic</RarityTitle> genesis blades</ModelIconTitle>
          </div>

          <div>
            <ModelIconContainer>
              {legendaryIcons}
            </ModelIconContainer>
            <ModelIconTitle>32 <RarityTitle kind="legendary">legendary</RarityTitle> Crypto Swords</ModelIconTitle>
          </div>

          <div>
            <ModelIconContainer>
              {swordIcons}
            </ModelIconContainer>
            <ModelIconTitle>
              967 <RarityTitle kind="epic">epic</RarityTitle> Crypto Swords
            </ModelIconTitle>
          </div>
        </MainInfoCard>

        <MainInfoCard title={"Gallery"} icon={emoji("🖼️")}>
          <div>
            <ImageGallery
              items={images}
              infinite={true}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              showBullets={false}
              lazyLoad={true}
              renderItem={(item) => {
                return <GalleryVideo url={item.original} />;
              }}
              renderLeftNav={(onClick) => {
                return <GalleryNavLeftButton onClick={onClick}>
                  <GiPlainDagger size="6em" />
                </GalleryNavLeftButton>;
              }}

              renderRightNav={(onClick) => {
                return <GalleryNavRightButton onClick={onClick}>
                  <GiPlainDagger size="6em" />
                </GalleryNavRightButton>;
              }}
            />
          </div>
        </MainInfoCard>

        <MainInfoCard title={"Announcements"} icon={emoji("📢")}>
          <TwitterTimelineContainerWrapper>
            <TwitterTimelineContainer>
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName="0xPolyForge"
                autoHeight={true}
                options={{
                  width: "100%",
                }}
              />
            </TwitterTimelineContainer>
          </TwitterTimelineContainerWrapper>
        </MainInfoCard>
      </div>

      <CreditFooter>
        Icon credit: <a href="https://game-icons.net/">https://game-icons.net/</a>
      </CreditFooter>
    </ContentContainer>
  );
}

export default PolybladeHome;

export const colors = {
  main: "#9E4AFF",
  secondary: "#19AEEE",
  background: "#2D3142",
  text: "#F7F7F9",
  emphasis: "#F7E733",
  success: "#41E2BA",
  error: "#DD403A"
};

export type RarityKind = "uncommon" | "rare" | "epic" | "legendary" | "mythic";

export interface RarityKindProps {
  kind?: RarityKind
}

export const rarityColors = {
  uncommon: {
    text: colors.text,
    background: colors.main,
  },
  rare: {
    text: "#a972e8",
    background: "#7532c2",
  },
  epic: {
    text: colors.secondary,
    background: "#8313b9",
  },
  legendary: {
    text: colors.success,
    background: "#9a1493",
  },
  mythic: {
    text: colors.emphasis,
    background: "#a01471",
  }
};
import styled from "@emotion/styled";
import * as styles from "../common/commonStyle";

export const TimerContainer = styled.div({
  display: "flex",
  flexFlow: "row nowrap",
});

export const TimeEntryContainer = styled.div({
  margin: "8px",
  color: styles.colors.text,
  backgroundColor: styles.colors.secondary,
  borderRadius: "20px",
  boxShadow: `2px 2px 0px ${styles.colors.background}`,

  width: "52px",
  fontSize: "12px",

  "@media(min-width: 400px)": {
    width: "64px",
    fontSize: "14px"
  },

  "@media(min-width: 450px)": {
    width: "90px",
    fontSize: "18px"
  },
});

export const TimeEntryValueText = styled.h3({
  fontSize: "20px",
  lineHeight: 0,

  "@media(min-width: 400px)": {
    fontSize: "24px"
  },

  "@media(min-width: 450px)": {
    fontSize: "32px"
  },
});

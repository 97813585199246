import styled from "@emotion/styled";
import * as styles from "./common/commonStyle";

export const AppContainer = styled.div({
  width: "100%",
  height: "100%",
});

export const AppHeaderWrapper = styled.div({
  width: "100%",
});

export const AppHeader = styled.div({
  backgroundColor: styles.colors.main,
  display: "flex",
  justifyContent: "space-between",
  fontSize: "32px",
  color: "white",
  padding: "6px 8px",
});

export const MediaLinkContainer = styled.div({
  display: "flex",
  flexFlow: "row",
});

export const MediaLink = styled.a({
  color: styles.colors.text,
  opacity: 0.75,
  transition: "all 0.1s",
  display: "flex",
  alignItems: "center",
  marginLeft: "16px",

  "&:hover": {
    opacity: 1,
  },

  "&:visited": {
    color: styles.colors.text,
  },
});

export const TitleLink = styled.a({
  textDecoration: "none",
  transition: "all 0.2s",
  display: "flex",
  alignItems: "center",

  "&:hover": {
    filter: "contrast(2)",
  },
});

export const TitleLogo = styled.img({
  width: "32px",
});

export const AppContent = styled.div({});

export const AppErrorHeader = styled.div({
  position: "fixed",
  width: "100%",
  backgroundColor: styles.colors.error,
  boxShadow: "0px 2px 1px rgba(0,0,0,0.5)",
  zIndex: 100,
  color: styles.colors.text,
  padding: "2px 10px",
});

export const ErrorCloseButton = styled.button({
  position: "absolute",
  right: "16px",
  top: "0",

  background: "none",
  color: styles.colors.text,
  border: "none",
  decoration: "none",
  fontSize: "26px",

  "&:hover": {
    cursor: "pointer",
  },
});

export const GlobalOverlay = styled.div({
  position: "fixed",
  height: "100vh",
  width: "100vw",
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: 100,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const GlobalLoadingContainer = styled.div({
  display: "flex",
  flexFlow: "column wrap",
  alignItems: "center",

  "h3": {
    color: styles.colors.text,
    textShadow: "2px 2px 2px black",
  }
});

export const ErrorModal = styled.div({
  backgroundColor: styles.colors.text,
  width: "600px",
  borderRadius: "20px",
  boxShadow: "0px 0px 10px black",
  padding: "32px",
  margin: "0 16px"
});

export const ErrorModalHeader = styled.h2({
  fontSize: "32px",
  margin: "8px 0",
  color: styles.colors.error,
  display: "flex",
  alignItems: "center",

  svg: {
    marginRight: "6px",
  },
});

export const ErrorModalText = styled.p({
  fontSize: "24px",
  margin: "16px 0",
});

export const StyledButtonContainer = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

export const StyledButton = styled.button({
  decoration: "none",
  border: "none",
  color: styles.colors.text,
  backgroundColor: styles.colors.main,
  borderRadius: "12px",
  fontSize: "24px",
  padding: "6px 16px",
  boxShadow: "2px 2px black",
  transition: "0.2s all",

  "&:hover": {
    filter: "contrast(1.5)",
    cursor: "pointer",
  },

  "&:active": {
    filter: "contrast(2)",
  },
});

export const AppErrorHeaderContent = styled.span({
  display: "flex",
  alignItems: "center",
  fontSize: "18px",

  "svg": {
    marginRight: "4px"
  }
});
import React, { useState, useRef } from "react";
import { IoMdPlay } from "react-icons/io";
import { ClipLoader } from "react-spinners";
import { colors } from "../common/commonStyle";
import { StyledVideo, PlayButton, StyledVideoWrapper } from "./GalleryVideo.styled";
import LazyLoad from "react-lazyload";

interface GalleryVideoProps {
  url: string
}

function GalleryVideo(props: GalleryVideoProps) {
  const [isPaused, setIsPaused] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef: React.MutableRefObject<HTMLVideoElement | null> = useRef(null);

  const playVideo = () => {
    const videoElement = videoRef?.current;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
        setIsPaused(false);
      } else {
        videoElement.pause();
        setIsPaused(true);
      }
    }
  };

  return (
    <div>
      <LazyLoad>
        <StyledVideo muted={isPaused} loop ref={videoRef} onClick={playVideo} onLoadedData={() => {
          setIsLoading(false);
        }}>
          <source src={props.url} type="video/mp4"/>
          Your browser does not support the video tag.
        </StyledVideo>
      </LazyLoad>

      {(isLoading || isPaused) &&
        <StyledVideoWrapper>
          {isLoading && <ClipLoader color={colors.text} size="3em" />}
          {isPaused && !isLoading && <PlayButton onClick={playVideo}>
            <IoMdPlay size="3rem" />
          </PlayButton>}
        </StyledVideoWrapper>
      }
    </div>
  );
}

export default GalleryVideo;

import { formatIpfsUrl } from "./utils";

export type EnvironmentMode = "dev" | "prod";
export const ENV_MODE: EnvironmentMode = "prod";

export const ENV_MAPPINGS = {
  "dev": {
    rpc: "https://rpc-mumbai.maticvigil.com",
    contract: "0x085b201ea2eCA0812c23a3E36442e0F1bE8b7501",
    oldContract: "0x1abc26233285932b6c6d6ad961702943988a9151",
    chainId: "0x13881"
  },
  "prod": {
    rpc: "https://polygon-mainnet.g.alchemy.com/v2/2J4z1NZkGeZ8O02hfLVDkEgtPYFf1x73",
    contract: "0xc3e46b11f6B4aAC8dE285DBFa104A3ea0Da91d34",
    oldContract: "0xf20077424d1cbe34265d2c2c8422748a6486ce28",
    chainId: "0x89"
  }
};

export const RPC_URL = ENV_MAPPINGS[ENV_MODE].rpc;
export const CONTRACT_ADDRESS = ENV_MAPPINGS[ENV_MODE].contract;
export const OLD_CONTRACT_ADDRESS = ENV_MAPPINGS[ENV_MODE].oldContract;
export const CHAIN_ID = ENV_MAPPINGS[ENV_MODE].chainId;

export const IPFS_BASE_URL = formatIpfsUrl("QmTkzMGeiC7Rss7G7jmPsoHZT2TrqsnCH2EWiuGNnCqPDX");
export const OPENSEA_LINK = "https://opensea.io/collection/polyblade";

export const WHITELISTED_TOKEN_IDS = [1];
export const MAX_MINT_COUNT = 5;
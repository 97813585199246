import styled from "@emotion/styled";
import * as styles from "../common/commonStyle";

export const StyledVideo = styled.video({
  cursor: "pointer",
  maxHeight: "90vh",
  width: "75%"
});

export const PlayButton = styled.div({
  backgroundColor: styles.colors.main,
  borderRadius: "10px",
  padding: "10px",
  cursor: "pointer",
  transition: "all 0.2s"
});

export const StyledVideoWrapper = styled.div({
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: 0,
  right: 0,
  pointerEvents: "none"
});

export const LoadingBarContainer = styled.div({
  position: "absolute",
  top: "50%",
  left: "50%"
});
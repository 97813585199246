import React, { useState } from "react";
import { useEffect } from "react";
import {
  TimerContainer,
  TimeEntryContainer,
  TimeEntryValueText,
} from "./CountdownTimer.styled";

interface CountdownTimerProps {
  targetTime: number;
}

function CountdownTimer(props: CountdownTimerProps) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    if (Math.floor(currentTime / 1000) >= props.targetTime) {
      return;
    }

    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    // Avoid mem leaks:
    // https://stackoverflow.com/questions/57137094/implementing-a-countdown-timer-in-react-with-hooks
    return () => clearInterval(intervalId);
  }, [currentTime]);

  function extractTimeDifference() {
    const timeNow = currentTime / 1000;
    let timeDiff = Math.max(0, props.targetTime - timeNow);

    const timeDays = Math.floor(timeDiff / 60 / 60 / 24);
    timeDiff -= timeDays * 60 * 60 * 24;

    const timeHours = Math.floor(timeDiff / 60 / 60);
    timeDiff -= timeHours * 60 * 60;

    const timeMinutes = Math.floor(timeDiff / 60);
    timeDiff -= timeMinutes * 60;
    timeDiff = Math.floor(timeDiff);

    return [
      {
        name: "Days",
        value: timeDays,
      },
      {
        name: "Hours",
        value: timeHours,
      },
      {
        name: "Minutes",
        value: timeMinutes,
      },
      {
        name: "Seconds",
        value: timeDiff,
      },
    ];
  }

  const timeDiffValues = extractTimeDifference();

  return (
    <TimerContainer>
      {timeDiffValues.map((value, id) => {
        return (
          <TimeEntryContainer key={id}>
            <TimeEntryValueText>{value.value}</TimeEntryValueText>
            <p>{value.name}</p>
          </TimeEntryContainer>
        );
      })}
    </TimerContainer>
  );
}

export default CountdownTimer;

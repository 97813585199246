import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import * as styles from "../common/commonStyle";

export const fadeInKeyframes = keyframes({
  from: {
    opacity: 0
  },
  to: {
    opacity: 1
  }
});

/** Parent div */
export const ContentContainer = styled.div({
  textAlign: "center",
  backgroundColor: "#2D3047",
  paddingBottom: "32px",
});

/** Main banner/front page */

export const MainBannerContainer = styled.div({
  display: "flex",
  width: "100%",
  minHeight: "100vh",
  backgroundImage: "url('./banner-background.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  animation: `${fadeInKeyframes} 1s linear`,
  // backgroundColor: "#4d586e"
});

export const MainBannerOverlayContainer = styled.div({
  backgroundColor: "rgba(100,100,100,0.55)",
  height: "100%",
  minHeight: "inherit",
  width: "100%",
  paddingBottom: "32px"
});

export const MainBannerImage = styled.img({
  maxWidth: "90%"
});

export const MainBannerButton = styled.button({
  backgroundColor: styles.colors.secondary,
  outline: "none",
  border: "none",
  color: "white",
  fontSize: "32px",
  margin: "12px 8px",
  position: "relative",
  transition: "all 0.1s",
  width: "150px",
  animation: `${fadeInKeyframes} 0.5s ease`,
  padding: "4px 0",

  "&:before": {
    content: "''",
    position: "absolute",
    bottom: "-5px",
    right: "-10px",
    width: "10px",
    height: "100%",
    background: "#54bbe8",
    transform: "skewY(45deg)",
    transition: "all 0.1s",
  },

  "&:after": {
    content: "''",
    position: "absolute",
    left: "5px",
    top: "auto",
    bottom: "-10px",
    right: "auto",
    width: "100%",
    height: "10px",
    background: "#299bcc",
    transform: "skewX(45deg)",
    transition: "all 0.1s",
  },

  "&:hover": {
    filter: "contrast(1.5)",
    cursor: "pointer",
  },

  "&:active": {
    marginLeft: "16px",
    filter: "contrast(2.0)",

    "&:after": {
      bottom: "-5px",
      height: "5px",
      left: "3px",
    },

    "&:before": {
      bottom: "-3px",
      width: "5px",
      right: "-5px",
    },
  },

  "&:disabled": {
    opacity: 0.6,

    "&:active": {},
    "&:hover": {},
  },
});

export const MainBannerButtonContainers = styled.div({
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const ProgressBarContainer = styled.div({
  display: "flex",
  flexFlow: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "50px",
  margin: "32px 0",
  animation: `${fadeInKeyframes} 0.5s ease`,
});

export const ProgressBar = styled.progress({
  margin: "0 12px",
  border: "0",
  borderRadius: "20px",
  width: "60%",
  minWidth: "320px",
  maxWidth: "1000px",
  height: "100%",
  boxShadow: `5px 5px 0px ${styles.colors.background}`,
  overflow: "hidden",
  backgroundColor: "#424863",

  "&::-webkit-progress-bar": {
    borderRadius: "20px",
    backgroundColor: "#424863",
    overflow: "hidden",
  },

  "&::-webkit-progress-value": {
    backgroundColor: styles.colors.success,
    overflow: "hidden",
  },

  "&::-moz-progress-bar": {
    backgroundColor: styles.colors.success,
    overflow: "hidden",
  },
});

export const ProgressBarText = styled.h5({
  position: "absolute",
  color: styles.colors.text,
  fontSize: "18px"
});

export const MintCounterContainer = styled.div({
  display: "flex",
  flexFlow: "row",
  fontSize: "32px",
  marginLeft: "8px"
});

export const MintCounterValueText = styled.h2({
  fontSize: "32px",
  color: styles.colors.text,
  margin: 0
});

export interface MintCounterIconProps {
  active?: boolean
}

export const MintCounterIcon = styled.p((props: MintCounterIconProps) => {
  return {
    margin: 0,
    filter: props.active ? undefined : "grayscale(1) contrast(1.25)"
  };
});

export const MintCounterButton = styled.button({
  decoration: "none",
  border: "none",
  color: styles.colors.text,
  backgroundColor: styles.colors.secondary,
  borderRadius: "16px",
  fontSize: "24px",
  transition: "0.2s all",
  margin: "0 8px",

  "&:hover": {
    filter: "contrast(1.5)",
    cursor: "pointer",
  },

  "&:active": {
    filter: "contrast(2)",
  },

  "&:disabled": {
    opacity: 0.5
  }
});

export const MainLoaderContainer = styled.div({
  marginTop: "24px",
  marginBottom: "64px",
});

/** About */

// export const AboutTextHeader = styled.h3({
//   fontSize: "22px",
//   margin: "32px 0"
// });

export const AboutText = styled.p({
  fontSize: "16px",
  display: "flex",
  alignItems: "start",
  margin: 0,
  marginTop: "12px",
  wordBreak: "break-word",

  "@media(min-width: 500px)": {
    fontSize: "18px",
  },

  "@media(min-width: 600px)": {
    fontSize: "20px",
  },

  "@media(min-width: 1000px)": {
    fontSize: "22px",
  },
});

export const AboutTextIcon = styled.span({
  marginTop: "-2px",
  marginRight: "8px",
  borderRadius: "12px",
  padding: "4px",
  backgroundColor: "#7600ff",
});

export const AboutMainContainer = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
});

export const AboutMainEntryIconProps = {
  fontSize: "24px",
  width: "24px",
  borderRadius: "32px",
  padding: "8px",
  backgroundColor: styles.colors.background,
  color: styles.colors.text,
  overflow: "clip",
  transition: "0.2s all",
  marginRight: "8px",
  marginBottom: "16px",

  "&:hover": {
    filter: "contrast(1.75) invert(1)",
    // color: styles.colors.emphasis
  },

  "@media(min-width: 450px)": {
    fontSize: "48px",
    width: "48px",
    marginRight: "12px",
    padding: "12px"
  },

  "@media(min-width: 600px)": {
    width: "60px",
    fontSize: "60px",
    marginRight: "24px",
    padding: "16px",
  },

  "@media(min-width: 1000px)": {
    width: "64px",
    fontSize: "64px",
    marginRight: "28px",
  }, 
};

export const AboutMainEntryImage = styled.img(AboutMainEntryIconProps);

export const AboutMainEntryContainer = styled.div({
  display: "grid",
  gridTemplateColumns: "auto auto",
  // flexFlow: "row wrap",
  alignItems: "flex-start",
  margin: "24px 4px",
  transition: "0.2s all",
  svg: AboutMainEntryIconProps,
});

export const AboutContentContainer = styled.div({
});

export const AboutEntryTitle = styled.h2({
  width: "100%",
  textShadow: "1px 1px black",
  margin: 0,
  marginBottom: "8px",
  fontSize: "18px",

  "@media(min-width: 500px)": {
    fontSize: "20px",
  },

  "@media(min-width: 600px)": {
    fontSize: "22px",
  },

  "@media(min-width: 1000px)": {
    fontSize: "24px",
  },
});

/** Token distribution */

export const ModelIconContainer = styled.div({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
});

export const ModelIcon = styled.img((props: styles.RarityKindProps) => {
  const bgColor = props.kind === "uncommon" ?
    "#7532c2" : (
      styles.rarityColors[props.kind || "uncommon"] ||
      styles.rarityColors["uncommon"]
    ).background;

  return {
    width: "100px",
    filter: "contrast(1.5)",
    transition: "transform 0.2s",
    backgroundColor: bgColor,
    borderRadius: "25px",
    padding: "5px",
    margin: "5px",
    boxShadow: "3px 3px 3px rgba(45,49,66,0.5)",

    "&:hover": {
      transform: "scale(1.25)",
      zIndex: 1,
    },
  };
});

export const ModelIconTitle = styled.h2({
  textAlign: "center",
});

export const RarityTitle = styled.span((props: styles.RarityKindProps) => {
  const colData = styles.rarityColors[props.kind || "uncommon"] || styles.rarityColors["uncommon"];
  
  return {
    color: colData.text,
    textShadow: "2px 2px black"
  };
});

/** Announcements */

export const TwitterTimelineContainer = styled.div({
  width: "650px",
  height: "700px",
  animation: `${fadeInKeyframes} 0.5s ease`
});

export const TwitterTimelineContainerWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
});

/** Gallery */

export const GalleryNavCommon: React.CSSProperties = {
  transform: "translateY(-50%)",
  top: "50%",
  padding: "50px 10px",
  background: "none",
  border: "none",
  zIndex: 4,
  color: styles.colors.text,
  position: "absolute",
  cursor: "pointer",
  outline: "none",
  transition: "0.2s all",
  fontSize: "1vw",
};

export const GalleryNavLeftButton = styled.button({
  ...GalleryNavCommon,
  left: "0",
  filter: "drop-shadow(4px 4px black)",

  "svg": {
    transform: "rotate(135deg)",
  },

  "&:hover": {
    color: styles.colors.secondary,
  },

  "&:active": {
    color: "#69d2ff",
  },

  "@media(max-width: 300px)": {
    visibility: "hidden"
  },

  "@media(max-width: 500px)": {
    left: "-10px"
  },
});

export const GalleryNavRightButton = styled.button({
  ...GalleryNavCommon,
  right: "0",
  filter: "drop-shadow(-3px 4px black)",

  "svg": {
    transform: "rotate(-45deg)",
  },

  "&:hover": {
    color: styles.colors.secondary,
  },

  "&:active": {
    color: "#69d2ff",
  },

  "@media(max-width: 300px)": {
    visibility: "hidden"
  },

  "@media(max-width: 500px)": {
    right: "-10px"
  },
});

/** Roadmap */

export const RoadmapText = styled.p({
  fontSize: "18px",
  margin: "16px 40px",
  maxWidth: "75%",

  "@media(max-width: 500px)": {
    maxWidth: "95%"
  },
});

export const RoadmapTextContainer = styled.div({
  wordBreak: "break-word",

  // "@media(min-width: 800px)": {
  //   display: "grid",
  //   gridTemplateColumns: "1fr 1fr",
  //   gridAutoFlow: "row",
  //   columnGap: "4%",

  //   ">*": {
  //     margin: "10px 10px"
  //   },

  //   // "& *:nth-child(2n)": {
  //   //   justifySelf: "end"
  //   // }
  // },
});

export const RoadmapTitle = styled.h2({
  textShadow: "1px 1px black",
  display: "flex",
  alignItems: "start",
  fontSize: "18px",

  "@media(min-width: 500px)": {
    fontSize: "20px",
  },

  "@media(min-width: 600px)": {
    fontSize: "22px",
  },

  "@media(min-width: 1000px)": {
    fontSize: "24px",
  },
});

/** Footer */
export const CreditFooter = styled.div({
  color: styles.colors.text,
  marginTop: "24px",
  textAlign: "center",
});

/** Shared */

export interface PolygonIconProps {
  size: "sm" | "md"
}

export const PolygonIcon = styled.img((props: PolygonIconProps) => {
  const sizeMap = {
    "sm": "1em",
    "md": "24px"
  };

  return {
    width: sizeMap[props.size],
    // filter: "brightness(0) invert(1)",
  };
});

// export const StyledHr = styled.hr({
//   border: 0,
//   height: "2px",
//   boxShadow: "1px 1px 1px black",
//   background: styles.colors.text
// });

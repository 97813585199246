const GATEWAYS = {
  "default": "ipfs.io",
  "dweb": "dweb.link",
  "cloudflare": "cloudflare-ipfs.com"
};

export function formatIpfsUrl(cid: string, video=false) {
  const GATEWAY_URL = video ? GATEWAYS.default : GATEWAYS.cloudflare;

  return `https://${GATEWAY_URL}/ipfs/${cid}`;
}
import styled from "@emotion/styled";
import * as styles from "../common/commonStyle";

export const MainInfoCardContainer = styled.div({
  backgroundColor: styles.colors.main,
  borderRadius: "16px",
  color: styles.colors.text,
  boxShadow: "3px 3px 3px rgba(0,0,0,0.5)",
  margin: "64px 10px",
  textAlign: "start",

  "@media (min-width: 768px)": {
    margin: "64px 64px"
  },

  "@media (min-width: 1200px)": {
    margin: "64px 100px"
  }
});

export const MainInfoCardContent = styled.div({
  padding: "16px 16px",

  "@media (min-width: 500px)": {
    padding: "16px 32px"
  }
});

export const IconContainer = styled.div({
  position: "absolute",
  top: "-44px",
  left: "8px",
  fontSize: "56px"
});

export const IconContainerWrapper = styled.div({
  position: "relative"
});

export const CardTitle = styled.h1({
  textShadow: "2px 2px rgba(0,0,0,0.5)",
  // fontSize: "32px"
});

export interface CardContentContainerProps {
  visible: boolean
}

export const CardContentContainer = styled.div((props: CardContentContainerProps) => {
  return {
    transition: "0.5s all",
    opacity: props.visible ? 1 : 0
  };
});
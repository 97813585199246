import React, { useState, useEffect, useRef } from "react";
import {
  MainInfoCardContainer,
  IconContainerWrapper,
  IconContainer,
  MainInfoCardContent,
  CardTitle,
  CardContentContainer,
} from "./MainInfoCard.styled";

interface MainInfoCardProps {
  icon?: JSX.Element | string;
  title: string;
  children?: React.ReactNode;
}

function MainInfoCard(props: MainInfoCardProps) {
  const domRef = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState(false);

  // https://stackoverflow.com/questions/59595700/how-to-make-a-react-component-fade-in-on-scroll-using-intersectionobserver-but
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisible(true);
      }
    });

    if (domRef.current !== null) {
      observer.observe(domRef.current);
      return () => {
        if (domRef.current) {
          observer.unobserve(domRef.current);
        }
      };
    }
  }, [domRef.current]);

  return (
    <MainInfoCardContainer>
      {props.icon && (
        <IconContainerWrapper>
          <IconContainer>{props.icon}</IconContainer>
        </IconContainerWrapper>
      )}
      <MainInfoCardContent ref={domRef}>
        <CardTitle>{props.title}</CardTitle>
        <CardContentContainer visible={isVisible}>
          {props.children}
        </CardContentContainer>
      </MainInfoCardContent>
    </MainInfoCardContainer>
  );
}

export default MainInfoCard;

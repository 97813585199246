import React, { useState } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { SiDiscord, SiTwitter } from "react-icons/si";

import PolybladeHome from "./pages/PolybladeHome";
import "./App.css";
import MintInfoPage from "./pages/MintInfoPage";
import { IoMdClose, IoMdWarning } from "react-icons/io";
import { CircleLoader } from "react-spinners";
import {
  AppContainer,
  GlobalOverlay,
  ErrorModal,
  ErrorModalHeader,
  ErrorModalText,
  StyledButtonContainer,
  StyledButton,
  AppHeaderWrapper,
  AppErrorHeader,
  ErrorCloseButton,
  AppHeader,
  TitleLink,
  TitleLogo,
  MediaLink,
  AppContent,
  GlobalLoadingContainer,
  AppErrorHeaderContent,
  MediaLinkContainer,
} from "./App.styled";

function App() {
  const [error, setError] = useState<string | null>(null);
  const [activeError, setActiveError] = useState<string | null>(null);
  const [globalLoadMessage, setGlobalLoadMessage] = useState<string | null>(null);

  function handleError(errMsg: string, kind: "active" | "passive") {
    if (kind === "active") {
      if (globalLoadMessage) {
        setGlobalLoadMessage(null);
      }

      setActiveError(errMsg);
    } else {
      setError(errMsg);
    }
  }

  return (
    <Router>
      <AppContainer>
        {activeError && (
          <GlobalOverlay>
            <ErrorModal>
              <ErrorModalHeader>
                <IoMdWarning /> Error!
              </ErrorModalHeader>
              <ErrorModalText>{activeError}</ErrorModalText>
              <StyledButtonContainer>
                <StyledButton
                  onClick={() => {
                    setActiveError(null);
                  }}
                >
                  Close
                </StyledButton>
              </StyledButtonContainer>
            </ErrorModal>
          </GlobalOverlay>
        )}

        {globalLoadMessage && (
          <GlobalOverlay>
            <GlobalLoadingContainer>
              <CircleLoader color="#9E4AFF" />
              <h3>{globalLoadMessage}</h3>
            </GlobalLoadingContainer>
          </GlobalOverlay>
        )}

        <AppHeaderWrapper>
          {error && (
            <AppErrorHeader>
              <AppErrorHeaderContent>
                <IoMdWarning /> {error}
              </AppErrorHeaderContent>
              <ErrorCloseButton
                onClick={() => {
                  setError(null);
                }}
              >
                <IoMdClose />
              </ErrorCloseButton>
            </AppErrorHeader>
          )}
          <AppHeader>
            <TitleLink href="./">
              <TitleLogo src="./logo.png" />
            </TitleLink>

            <div>
              <MediaLinkContainer>
                <MediaLink href="https://twitter.com/0xPolyForge">
                  <SiTwitter />
                </MediaLink>

                <MediaLink href="https://discord.gg/kQ3QBaeDkA">
                  <SiDiscord />
                </MediaLink>
              </MediaLinkContainer>
            </div>
          </AppHeader>
        </AppHeaderWrapper>

        <AppContent>
          <Switch>
            <Route path="/success">
              <MintInfoPage />
            </Route>
            <Route path="/">
              <PolybladeHome
                onError={handleError}
                onGlobalLoad={(msg: string | null) => {
                  setGlobalLoadMessage(msg);
                }}
              />
            </Route>
          </Switch>
        </AppContent>
      </AppContainer>
    </Router>
  );
}

export default App;
